.fixed-div {
    position: fixed;
    top: 20;
    right: 0;
    z-index: 999; /* Asegura que el div siempre esté por encima del resto del contenido */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color:black;
  }
  
  .content {
    padding-top: 10px; /* Ajusta el margen superior para evitar que el contenido se solape con el div fijo */
    padding-left: 10px;
    padding-bottom: 10px;
    background-color:  	#b22222;
    border-radius: 5px;
    color:white   /* ... */
  }
  .content-1 {
    padding-top: 10px; /* Ajusta el margen superior para evitar que el contenido se solape con el div fijo */
    padding-left: 10px;
    padding-bottom: 10px;
    border-radius: 5px; 
  }


  .app-container {
    position: relative;
    width: 100%;
    height: 1000px;
     /* Ajusta la altura según tus necesidades */
  }

  .scroll-container {
    width: 58vw; /* o el ancho que desees */
    height: 25vh; /* o la altura que desees */
    overflow-y: scroll; /* añade un scroll vertical */
    font-size: 12px;
  }
  
  .content-1 ul {
    list-style-type: none; /* quita los puntos de la lista */
    padding: 0;
    margin: 0;
  }
  
  .content-1 ul li {
    margin-bottom: 1px;
    padding: 1px;
    cursor: pointer;

  }

  .content-1 ul li.selected {
    background-color: #E8EAF6; /* color de fondo para el elemento seleccionado */
  }

  .controlYears{
    width: 100%;
    display: flex;
    justify-content: center; /* Espacio entre los elementos */
    align-items: center; /* Centra verticalmente los elementos */
  }

  .controlYearsSelector{
    width: 150px;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
   /* Espacio entre los elementos */
    align-items: center; /* Centra verticalmente los elementos */
  }

