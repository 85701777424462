.navbar0 .nav-link:hover,
.navbar0 .dropdown-item:hover {
  background-color: #888888;
  color: white;

}

.navbar0 .dropdown-item:hover .MuiSvgIcon-root {
  fill: white;
}

.navbar0 .navbar-brand:hover img {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.ml-auto-custom {
  margin-left: auto;
  margin-right: 60px;
}