.parametrizacion-container {
  display: flex;
  width: 90vw;
  font-size: 12px;
  margin-left: 5vw; 
  margin-right: 5vw; 
  margin-top: 150px;
  height: 70vh; /* Ajusta la altura de la pantalla */
}

.droppable-content {
  width: 25vw;
  height: 100%;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  overflow-y: auto;
  padding: 10px;
  max-height: 100%; /* Para que ocupe el 100% del contenedor padre */
  border-radius: 10px;

  
}

.droppable-item {
  padding: 8px;
  margin: 4px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  cursor: pointer;
  width: 100%; /* Ajusta el ancho al 100% del contenedor padre */
}

.draggable-list {
  width: 15vw;
  height: 100%;
  overflow-y: auto; /* Habilita el scroll vertical */
  padding: 10px;
  background-color: #f1f1f1;
  border-left: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 15vw;
  max-height: 100%; /* Para que ocupe el 100% del contenedor padre */
}

.draggable-item {
  padding: 8px;
  margin: 4px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  cursor: pointer;
  width: 100%; /* Ajusta el ancho al 100% del contenedor padre */
}

.droppable-area {
  width: 100%; 
  height: 100%;
  
}

.container-selector-model-despiece {
  margin-left: 2vw; 
  margin-right: 2vw; 
  position: fixed; 
  top: 150px; 
  left: 20px; 
  width: 200px; 
  z-index: 1000; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.container-selector-clasification-despiece{
  margin-left: 2vw; 
  margin-right: 2vw; 
  position: fixed; 
  top: 250px; 
  left: 20px; 
  width: 200px; 
  z-index: 1000; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

}