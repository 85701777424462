
  
  .contentImagesParts {
    display: flex;
    justify-content: center;
    padding-top: 10px; /* Ajusta el margen superior para evitar que el contenido se solape con el div fijo */
    padding-bottom: 10px;
    background-color:  	#b22222;
    border-radius: 5px;
    color:white;   /* ... */
    width: 40vw;

  }

  .content-1ImagesParts {
    display: flex;
    justify-content: left;
    padding-top: 10px; /* Ajusta el margen superior para evitar que el contenido se solape con el div fijo */
    /*padding-left: 10px; */
    padding-bottom: 10px;
    border-radius: 5px;
    margin-left: 10vw;
    width: 40vw;
  }

  .containerDataTableMui {
    margin-top: 5vh;
    margin-left: 10vw;
    margin-right: 10vw;
    width: 80vw;
  }



